import React from "react"
import "./styles.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function CoverStrip() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  return (
    <div className="coverstrip">
      <img
        alt=""
        className="coverstrip__desktop"
        src="https://school-live.com/assets/images/hero-image-desktop.jpg"
      ></img>

      <div className="coverstrip__mobile">
        <Slider {...settings}>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-1.jpg"
            ></img>
          </div>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-2.jpg"
            ></img>
          </div>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-3.jpg"
            ></img>
          </div>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-4.jpg"
            ></img>
          </div>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-5.jpg"
            ></img>
          </div>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-6.jpg"
            ></img>
          </div>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-7.jpg"
            ></img>
          </div>
          <div className="coverstrip__slide">
            <img
              alt=""
              src="https://school-live.com/assets/images/hero-image-mobile-8.jpg"
            ></img>
          </div>
        </Slider>
      </div>
    </div>
  )
}

export default CoverStrip
