import React from "react"
import "./style.scss"
import monthly_sub_img from "../../images/covers/2023/JULY_2023.jpg"
import sticker_img from "../../images/popup-sticker.png"
import constants from "../../constants/contants"
export default function HomePopup() {
  function onClose() {
    const popup = document.querySelector(".homepopup")
    popup.classList.remove("homepopup--active")
  }
  return (
    <div className="homepopup">
      <div className="homepopup__container">
        <button onClick={onClose} className="homepopup__close">
          <i className="fa fa-times"></i>
        </button>
        <a className="announcement_link" href="/subscriptions">
          <div className="announcement">
            <img src={constants.currentCoverLink} alt=""></img>
            {/* <img className="announcement__sticker" src={sticker_img} alt></img> */}
            {/* <h2>
              July 2023 Issue
              <br />
              Available Now!
            </h2> */}
          </div>
        </a>
      </div>
    </div>
  )
}
