import React from "react"
import "./style.scss"
import { Link } from "gatsby"

export default function BottomConnect() {
  return (
    <div className="bottomconnect" id="connect">
      <hr className="hr"></hr>
      <p className="bottomconnect__text">
        <br />
        Learn more about School LIVE magazine's content, reach and audience.
        <br />
      </p>
      <div className="bottomconnect__call">
        Get in touch today - call 9811042147, 9625951969
      </div>
      <br />
      <Link to="/connect" className="button-primary">
        Connect
      </Link>
    </div>
  )
}
