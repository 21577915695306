import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import FeaturedIssues from "../components/featuredissues"
import InstitutionalMembers from "../components/institutionalmembers"
import Advertise from "../components/advertise"
import BottomConnect from "../components/bottomconnect"
import HomePopup from "../components/HomePopup"
import CoverStrip from "../components/coverstrip"
import Testimonial from "../components/testimonial"

const IndexPage = () => (
  <Layout>
    <HomePopup />
    <SEO
      title="School LIVE | The Magazine for Students and their Parents"
      description="A renowned monthly magazine that goes to over 500 schools, pan India with a total readership of more than 1 lakh."
    />
    <Hero />
    <CoverStrip />
    <About />
    <Testimonial />
    <FeaturedIssues />
    {/* <InstitutionalMembers /> */}
    <Advertise />
    <BottomConnect />
  </Layout>
)

export default IndexPage
