import React from "react"
import "./style.scss"
import { Link } from "gatsby"

export default function About() {
  return (
    <div className="about" id="about">
      <div className="row about__row">
        <div className="col col-12 col-lg-4">
          <h2 className="about__heading">
            About <div style={{ display: "inline-block" }}>School LIVE</div>
          </h2>
          {/* <div className="meettheteam--desktop">
            <Link className="button-primary mt-sm" to="/team">
              Meet the Team
            </Link>
          </div> */}
          <br />
        </div>
        <div className="col col-12 col-lg-8">
          <p className="about__text">
            Future Ready Education Services (P) Ltd. was incorporated with
            intent to bridge the divide between education sector and media
            industry. Its first initiative, School LIVE is a monthly magazine,
            to bring useful content to the fore, and at the same time,
            amalgamate entertainment value in productive and joyful learning.
            <br />
            <br />
            The idea was also to enable and empower students with right
            interface to various areas of activity leading to recognition. The
            company also proposes to help institute scholarships and awards by
            major players in various fields and help students nurture their
            talents and pursue their goals in life.
            <br />
            <br />
            The magazine School LIVE, a monthly, highlights achievement and
            rewards talent in any form. Headed by Hariharan Balagopal, a
            specialist in Media and Education space , who has established
            numerous schools from concept to commissioning, the Magazine is a
            sum total of all the knowledge and experience gathered and collected
            over a period of 25 years.
            <br />
            <br />
            As a media vehicle, the magazine helps many students from the weaker
            sections by providing them with tangible products and learning
            material on the one hand from sponsors and promoters, and
            advertising value to the latter on the other. Focussed on School
            sector, the company proposes to provide multiple integrated
            platforms with a common intent to benefit larger sections of the
            school-students population one way or the other.
            <br />
            <br />
            The January 2024 edition of School Live is its 66th issue.
          </p>

          {/* <div className="meettheteam--mobile">
            <Link className="button-primary mt-sm" to="/team">
              Meet the Team
            </Link>
          </div> */}
        </div>
      </div>
      <br />
    </div>
  )
}
