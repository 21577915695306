import React, { useState } from "react"
import "./style.scss"
import { clients } from "./data"
// import Slider from "react-slick"
import { Link } from "gatsby"

export default function Advertise() {
  const [initialValue, setInitialValue] = useState(0)
  const [finalValue, setFinalValue] = useState(8)

  /*   function next() {
    let ini = initialValue
    let fin = finalValue
    if (ini === 8 && fin === 16) {
      ini = 16
      fin = 19
    } else if (ini === 16 && fin === 19) {
      ini = 0
      fin = 8
    } else {
      ini = initialValue + 8
      fin = finalValue + 8
    }
    setInitialValue(ini)
    setFinalValue(fin)
  }
 */
  const clientJSX = clients.map((client, i) => (
    <div key={i} className="col col-4 col-lg-2">
      <a href={client.link} target="__blank" rel="noreferrer">
        <img
          className="advertise__clients__img"
          alt={client.alt}
          src={client.src}
        ></img>
      </a>
    </div>
  ))

  return (
    <div className="advertise" id="advertising">
      <hr className="hr" />
      <h2 className="heading-primary">Advertising with School LIVE</h2>

      <div className="row advertise__row">
        <div className="col col-12 col-lg-12">
          <div className="advertise__clients">
            <div className="row">{clientJSX}</div>
            {/*  <button className="advertise__clients__nextbutton" onClick={next}>
              &#8680;
            </button> */}
          </div>
        </div>

        <div className="col col-12 col-lg-12">
          <br />
          School LIVE Magazine reaches over 20,000 identifiable readers in the
          niche target groups of students from Grade VI to Grade XII, and their
          parents, in the age bracket of 35 to 45.
          <br />
          <br />
          <div className="advertise__contact">
            <h3>
              <strong style={{ color: "#0D45F5" }}>
                For Advertising Enquiries
              </strong>
              <br />
              Email: <strong>impact@school-live.com</strong> <br />
              Or call: <strong>+91- 9625951969, 9899976367</strong>{" "}
            </h3>
          </div>
          <br />
          <center>
            {/*   <a
              className="button-primary"
              href="http://school-live.com/assets/docs/Advertisement.pdf"
              target="__blank"
            >
              Rate Card
            </a> */}
            <Link
              to="/connect"
              className="button-primary"
              // style={{ marginLeft: "20px" }}
            >
              Enquire Now
            </Link>
          </center>
        </div>
      </div>
    </div>
  )
}
