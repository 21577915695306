import React from "react"
import "./style.scss"
import { memberLogos } from "./data"

import Carousel from "../clientcarousel.component"
import goldIcon from "../../images/regoldsilverplatinum/Gold.png"
import goldPlusIcon from "../../images/regoldsilverplatinum/GoldPlus.png"
import silverIcon from "../../images/regoldsilverplatinum/Silver.png"
import silverPlusIcon from "../../images/regoldsilverplatinum/SilverPlus.png"
import platinumIcon from "../../images/regoldsilverplatinum/Platinum.png"
import platinumPlusIcon from "../../images/regoldsilverplatinum/PlatinumPlus.png"

// import { useState } from "react"
import { Link } from "gatsby"

const cards = [
  {
    name: "Silver",
    icon: silverIcon,
    data: `<div class="institutional-members__cards__card__price__copies">100 Copies X 10 Months</div>
    <div class="institutional-members__cards__card__price__heading">Annual Fee</div>
    <div class="institutional-members__cards__card__price__amount">&#8377; 65,000/-</div>`,
  },
  {
    name: "Silver Plus",
    icon: silverPlusIcon,
    data: `<div class="institutional-members__cards__card__price__copies">200 Copies X 10 Months</div>
  <div class="institutional-members__cards__card__price__heading">Annual Fee</div>
  <div class="institutional-members__cards__card__price__amount">&#8377; 1,20,000/-</div>`,
  },
  {
    name: "Gold",
    icon: goldIcon,
    data: `<div class="institutional-members__cards__card__price__copies">300 Copies X 10 Months</div>
  <div class="institutional-members__cards__card__price__heading">Annual Fee</div>
  <div class="institutional-members__cards__card__price__amount">&#8377; 1,65,000/-</div>`,
  },
  {
    name: "Gold Plus",
    icon: goldPlusIcon,
    data: `<div class="institutional-members__cards__card__price__copies">400 Copies X 10 Months</div>
  <div class="institutional-members__cards__card__price__heading">Annual Fee</div>
  <div class="institutional-members__cards__card__price__amount">&#8377; 2,00,000/-</div>`,
  },
  {
    name: "Platinum",
    icon: platinumIcon,
    data: `<div class="institutional-members__cards__card__price__copies">500 Copies X 10 Months</div>
  <div class="institutional-members__cards__card__price__heading">Annual Fee</div>
  <div class="institutional-members__cards__card__price__amount">&#8377; 1,99,000/-</div>`,
  },
  {
    name: "Platinum Plus",
    icon: platinumPlusIcon,
    data: `<div class="institutional-members__cards__card__price__copies">1000 Copies X 10 Months</div>
  <div class="institutional-members__cards__card__price__heading">Annual Fee</div>
  <div class="institutional-members__cards__card__price__amount">&#8377; 3,70,000/-</div>`,
  },
]

const cardsToDisplay = [...cards].reverse()

export default function InstitutionalMembers() {
  // const [currentTab, setCurrentTab] = useState(tabs[0])

  // function changeCurrentTab(tab) {
  //   setCurrentTab(tab)
  // }

  const cardsJSX = cardsToDisplay.map((card, i) => (
    <div className="col col-6 col-lg-4">
      <div className={`institutional-members__cards__card`} key={i}>
        <img
          className="institutional-members__cards__card__img"
          src={card.icon}
        />
        <div className="institutional-members__cards__card__heading">
          {card.name}
        </div>

        <div
          className="institutional-members__cards__card__price"
          dangerouslySetInnerHTML={{ __html: card.data }}
        ></div>
      </div>
    </div>
  ))

  return (
    <div className="institutional-members" id="membership">
      <hr className="hr" />
      <h2 className="heading-primary">Institutional Members</h2>

      <Carousel images={memberLogos} />
      {/* <div className="row institutional-members__row">
        <div className="col col-12 col-lg-6">
          <div className="institutional-members__logos-container">
            <div className="institutional-members__cards">
              <div className="row">{cardsJSX}</div>
            </div>
          </div>
        </div>
        <div className="col col-12 col-lg-6 institutional-members__card">
          Institutional Membership of the elite R.E.A.D Club of School LIVE{" "}
          <br />
          <br />
          <span className="institutional-members__tick">✓</span> Quality
          information, edutainment and talent encouragement avenues for students
          <br />
          <br />
          <span className="institutional-members__tick">✓</span> Exquisite
          product sample with each copy of at least one School LIVE Issue
          <br />
          <br />
          <span className="institutional-members__tick">✓</span> Coverage of
          your School in atleast one School LIVE issue
          <br />
          <br />
          Choose your school’s membership type and number of copies and begin
          reaping the benefits of the Club today.
          <br />
          <br />
          <Link to="/connect" className="button-primary">
            Enquire Now
          </Link>
        </div>
      </div> */}
    </div>
  )
}
