import React from "react"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./style.scss"

export default function Testimonial() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const data = [
    {
      name: "Aditi Misra",
      imgUrl:
        "https://school-live.com/assets/images/testimonials/Aditi-Misra.jpg",
      designation: "Director Principal",
      school: "DPS Sec-45 Gurgaon",
      testimonial:
        "School Live has been a great platform for students across the country to voice their opinions, to share what they think, to participate in competitions and festivals organized by School Live. I found creative expression in many ways on the portal of School Live.",
    },
    {
      name: "Anita Malhotra",
      imgUrl:
        "https://school-live.com/assets/images/testimonials/Anita-Malhotra.jpg",
      designation: "Director Principal",
      school: "Lotus Valley International School Gurgaon",
      testimonial:
        "School Live has always been a frontrunner in providing quality content to the readers, and the latest edition, true to its style, encapsulates inspiring stories and fascinating vignettes of information from around the world.",
    },
    {
      name: "Meenu Goswami",
      imgUrl:
        "https://school-live.com/assets/images/testimonials/Meenu-Goswami.jpg",
      designation: "Director Principal",
      school: "Bal Bharti Public School, Pitampura",
      testimonial:
        "Like always, School Live lives up to its name, and brings lively topics to the fore, while keeping alive what is traditionally ours. The magazine lover is justified in revisiting and reading these pages again, again and again!",
    },
    {
      name: "Archana Soni",
      imgUrl:
        "https://school-live.com/assets/images/testimonials/Archana-Soni.jpg",
      designation: "Principal",
      school: "Chinmaya Vidyalaya",
      testimonial:
        "School LIVE deals with variety effectively. True to what it claims, there is something in it for everyone.",
    },
    {
      name: "Guneet Ohri",
      imgUrl:
        "https://school-live.com/assets/images/testimonials/Guneet-Ohri.jpg",
      designation: "Founder Principal",
      school: "Suncity School",
      testimonial:
        "May the knowledge shared within these pages continue to ignite “the desire to satiate the appetite for additional literary material.”",
    },
    {
      name: "Akhilesh Chandra Chaturvedi",
      imgUrl:
        "https://school-live.com/assets/images/testimonials/Akhilesh-Chandra-Chaturvedi.jpg",
      designation: "Principal",
      school: "DPS Maruti Kunj Gurgaon",
      testimonial:
        "School Live is known to steer clear of mundane topics to present knowledge-based, entertaining content. The magazine is a cheerful companion in the warm confines and comfort of your homes, particularly when you choose a relaxing read!",
    },
  ]

  return (
    <section className="testimonials">
      <hr className="hr" />
      <h2 className="heading-primary">Principal Point</h2>
      <div className="testimonials__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div
              key={`testimonials-item-${i}`}
              className="testimonials__slider__slide"
            >
              <div className="testimonials__card">
                <div className="testimonials__card__img">
                  <img alt="" src={item.imgUrl}></img>
                </div>
                <div className="testimonials__card__text">
                  <div className="testimonials__card__text__testimonial">
                    <span style={{ color: "#E85122" }}>❝</span>{" "}
                    {item.testimonial}{" "}
                    <span style={{ color: "#E85122" }}>❞</span>
                  </div>
                  <div className="testimonials__card__text__name">
                    {item.name}
                  </div>
                  <div className="testimonials__card__text__designation">
                    {item.designation}, {item.school}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}
