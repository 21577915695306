import React from "react"
import "./style.scss"
import PropTypes from "prop-types"

function InsidePreview({ src, show, onClickClose }) {
  return (
    <div className={`inside-preview ${show && " inside-preview--show"}`}>
      <img src={src} className="inside-preview__image" />
      <button className="inside-preview__cross" onClick={onClickClose}>
        &#10006;
      </button>
    </div>
  )
}
InsidePreview.defaultProps = {
  show: false,
}

InsidePreview.propTypes = {
  src: PropTypes.string.isRequired,
  onClickClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default InsidePreview
